<template>
  <div class="greenEnergyExpert_box">
    <div class="head_nav">
      <div class="title">- 绿能专家 -</div>
      <div class="slogan">基于能源物联网的酒店绿能管理专家</div>
    </div>
    <div class="content_nav">
      <div class="product_box">
        <div class="label">产品架构</div>
        <div class="en_label">Product architecture</div>
        <div class="product_content">
          <div class="img_box">
            <img src="https://sys.huwei.xin:7443/huwei-website/product_architecture_img.png" alt="绿能专家-产品架构图">
          </div>
          <div class="menu_box">
            <div class="item">
              <div class="mark_line"><span class="dot"></span><span class="line"></span></div>
              <div class="text_box">
                <div class="label_text">云服务平台</div>
                <div class="description">提升数据存储，分析及辅助决策</div>
              </div>
            </div>
            <div class="item">
              <div class="mark_line"><span class="dot"></span><span class="line"></span></div>
              <div class="text_box">
                <div class="label_text">通信管理机</div>
                <div class="description">负责酒店能源数据传输</div>
              </div>
            </div>
            <div class="item">
              <div class="mark_line"><span class="dot"></span><span class="line"></span></div>
              <div class="text_box">
                <div class="label_text">数据采集装置</div>
                <div class="description">负责单元级能源数据采集</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module_box">
        <div class="img">
          <img src="https://sys.huwei.xin:7443/huwei-website/module_img.png" alt="">
        </div>
        <div class="function_box">
          <div class="label_box">
            <div class="label">模块组成</div>
            <div class="en_label">Module composition</div>
          </div>
          <div class="item_box">
            <div class="item" v-for="item in moduleList" :key="item.label">
              <div class="label_text">{{ item.label }}</div>
              <div class="description">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="hardware_box">
        <div class="label">硬件装置</div>
        <div class="en_label">Hardware device</div>
        <div class="hardware_content">
          <div class="content_box">
            <div class="title_box">
              <div>采集装置</div>
              <div>采集数据</div>
            </div>
            <div class="item_box" v-for="(item, index) in hardwareList" :key="index">
              <div class="item">
                <div class="index">{{ '0' + (index+1) }}</div>
                <div class="item_text">{{ item.device }}</div>
              </div>
              <div class="item">
                <div class="index">{{ '0' + (index+1) }}</div>
                <div class="item_text">{{ item.label }}</div>
              </div>
            </div>
          </div>
          <div class="right_img">
            <img src="https://sys.huwei.xin:7443/huwei-website/hardware_img.png" alt="">
          </div>
        </div>
      </div>
      <div class="support_nav">
        <div class="function_box">
          <div class="label">辅助决策</div>
          <div class="en_label">Decision support</div>
        </div>
        <div class="desc_box">
          <div class="title">影响能耗变化因素</div>
          <div class="desc">·重大活动假日   ·天气变化   ·住宿人员的构成变化  ·房间电器设备、线路老化程度以及隐藏故障   ·房间大小、位置、阴阳面、窗户大小   ·其他各种未知因素</div>
        </div>
        <div class="step_box">
          <div class="item">
            <div class="index_box">
              <div class="index_bg">01</div>
              <div class="label">收集样本</div>
            </div>
            <div class="decription">
              收集酒店客房的平均能耗值，过虑样本噪点，结合用户能耗标签，计算客房单位时间能耗，统计排名。
            </div>
          </div>
          <div class="icon">
            <el-icon size="30"><ArrowRightBold /></el-icon>
          </div>
          <div class="item">
            <div class="index_box">
              <div class="index_bg">02</div>
              <div class="label">完善数据</div>
            </div>
            <div class="decription">
              随着时间的推移和采集样本的增加，列表愈加准确，通过机器学习和数据筛选，完善样本。
            </div>
          </div>
          <div class="icon">
            <el-icon size="30"><ArrowRightBold /></el-icon>
          </div>
          <div class="item">
            <div class="index_box">
              <div class="index_bg">03</div>
              <div class="label">决策方案</div>
            </div>
            <div class="decription">
              通过数据积累和机器训练，快速形成酒店客房能耗“名单”，优化日常开房决策。
            </div>
          </div>
        </div>
      </div>
      <div class="core_box">
        <div class="label">核心技术</div>
        <div class="en_label">Core technologies</div>
        <div class="menu_box">
          <div class="item" v-for="item in menuList" :key="item.label">
            <img :src="('https://sys.huwei.xin:7443/huwei-website/icon/' + item.icon)" alt="">
            <div class="label_text">{{ item.label }}</div>
            <div class="description">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <div class="applications_nav">
        <div class="function_box">
          <div class="label">产品应用</div>
          <div class="en_label">Applications</div>
        </div>
        <div class="hotel_box">
          <div class="content_box" v-for="item in applicationList" :key="item.name">
            <img class="img_bg" :src="('https://sys.huwei.xin:7443/huwei-website/' + item.bg)" alt="">
            <div class="text_box">
              <div class="name">{{ item.name }}</div>
              <div class="li" v-for="(obj, index) in item.menuList" :key="index">
                {{ index+1 }} 、{{ obj.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, getCurrentInstance   } from "vue";
import { ArrowRightBold } from '@element-plus/icons'
export default {
  name: "greenEnergyExpert",
  components: {
    ArrowRightBold
  },
  setup() {
    const dom = getCurrentInstance()
    const state = computed(() => useStore().state.client);
    const moduleList = ref([
      {
        label: '硬件装置',
        description: '电能数据采集装置能够同时监测24路进线（220V、380V）的房间、电梯或着热水设备的能耗，体积小巧安装方便。'
      },
      {
        label: '无线通信',
        description: '电能采集装置内置ROLA无限信号传输装置，支持自组网，在酒店内部建立无线局域网，将数据发送至服务器。'
      },
      {
        label: '数据模型',
        description: '大数据采集和机器自学形成的酒店房间能耗模型、客户用点行为模型等，为酒店提供优化排房方案、异常用电告警'
      },
      {
        label: '辅助决策',
        description: '采纳提供管理决策建议，可直接降低酒店整体能耗，有效防止飞单现象，直接或者间接的增加酒店收益。'
      },
    ])
    const hardwareList = ref([
      {
        device: '电压互感器',
        label: '漏电流'
      },
      {
        device: '电流互感器',
        label: '房间电流'
      },
      {
        device: '烟雾传感器',
        label: '进线电压'
      },
      {
        device: '漏电流传感器',
        label: '漏电流传感器'
      },
      {
        device: '温湿度传感器',
        label: '配电箱温度'
      },
    ])
    const menuList = ref([
      {
        icon: 'data_icon.png',
        label: '开房算法',
        description: '结合客房能耗标签排名、本酒店房间类型、定价，客人入住长短、客人的能耗标签等各方面因素，给出客房开房推荐列表'
      },
      {
        icon: 'analysis_icon.png',
        label: '能耗标签',
        description: '根据租客的入住和离店时间，以及客人在本次入住的能耗总量，并考虑房间的综合能耗，对租客进行标签分类和行为分析'
      },
      {
        icon: 'calculate_icon.png',
        label: '入侵监测',
        description: '分析客人自带电器的行为，大功率电器直接可能造成房间供电线路的过载，从而引发火灾，此算法提出告警，及时消除隐患'
      },
      {
        icon: 'cloud_service_icon.png',
        label: '飞单管理',
        description: '根据房间的电力消耗和功率走势，判断房间是否有客人入住，可有效地管理飞单行为，减少飞单的发生，提升酒店收益'
      },
    ])
    const applicationList = ref([
      {
        name: '单体酒店',
        bg: 'monomer_hotel_img.png',
        menuList: [
          {
            label: '排房方案优化'
          },
          {
            label: '非正常用电告警'
          },
          {
            label: '用电隐患监测'
          },
          {
            label: '同类酒店能耗对比'
          },
        ]
      },
      {
        name: '连锁酒店',
        bg: 'chain_hotel_img.png',
        menuList: [
          {
            label: '排房方案优化'
          },
          {
            label: '非正常用电告警'
          },
          {
            label: '用电隐患监测'
          },
          {
            label: '同级别、同系列酒店能耗对比'
          },
          {
            label: '酒店内部能耗名单和价格辅助策略'
          },
          {
            label: '酒店内部线路和电器残值分析和更换提醒'
          },
        ]
      },
      {
        name: '订房平台',
        bg: 'reservation_platform_img.png',
        menuList: [
          {
            label: '能耗名单和客房报价辅助策略'
          },
          {
            label: '住客的行为分析'
          },
          {
            label: '精准广告推送'
          },
        ]
      },
    ])
    return {
      dom,
      state,
      moduleList,
      hardwareList,
      menuList,
      applicationList
    }
  }
}
</script>
<style lang="scss" scoped>
.greenEnergyExpert_box {
  width: 100%;
  .head_nav {
    background: url('https://sys.huwei.xin:7443/huwei-website/green_energy_expert_head_bg.png') no-repeat 100% 100%;
    background-size: cover;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    .title {
      font-size: 22px;
      line-height: 48px;
    }
    .slogan {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .content_nav {
    position: relative;
    .product_box {
      background: #ffffff;
      height: 956px;
      padding: 130px 200px;
      box-sizing: border-box;
      .label {
        font-size: 24px;
        font-weight: 800;
        color: #0F0F0F;
        line-height: 30px;
        border-left: 4px solid #1667FF;
        padding-left: 8px;
      }
      .en_label {
        font-size: 18px;
        font-weight: 600;
        color: #5E6070;
        line-height: 22px;
        padding: 8px 12px;
      }
      .product_content {
        display: flex;
        padding: 100px 100px 100px 178px;
        width: 100%;
        box-sizing: border-box;
        .img_box {
          width: 592px;
          height: 479px;
          img {
            width: 592px;
            height: 479px;
          }
        }
        .menu_box {
          display: grid;
          padding: 40px;
          width: 100%;
          grid-template-rows: repeat(3, 1fr);
          grid-gap: 115px;
          .item {
            display: flex;
            .mark_line {
              display: flex;
              height: 10px;
              position: relative;
              top: 17px;
              margin-right: 13px;
              .dot {
                width: 10px;
                height: 10px;
                background: #1667FF;
                border-radius: 50%;
              }
              .line {
                border-bottom: 1px dashed #1667ff;
                width: 100px;
                position: relative;
                top: -4px;
              }
            }
            .text_box {
              .label_text {
                font-size: 28px;
                line-height: 44px;
                font-weight: 600;
                color: #333333;
              }
              .description {
                font-size: 24px;
                font-weight: 400;
                line-height: 33px;
                color: #3C3B3B;
              }
            }
          }
        }
      }
    }
    .module_box {
      display: grid;
      grid-template-columns: 690px auto;
      .img {
        height: 802px;
        img {
          height: 802px;
        }
      }
      .function_box {
        background: #1667FF;
        position: relative;
        height: 802px;
        .label_box {
          padding: 100px 200px 80px;
          position: relative;
          float: right;
        }
        .label {
          font-size: 24px;
          font-weight: 800;
          color: #ffffff;
          line-height: 30px;
          text-align: right;
          border-right: 4px solid #ffffff;
          padding-right: 8px;
        }
        .en_label {
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
          line-height: 22px;
          padding: 8px 12px;
        }
        
        .item_box {
          width: 100%;
          display: grid;
          justify-content: center;
          padding: 0 200px 100px 150px;
          grid-gap: 35px;
          box-sizing: border-box;
          grid-template-columns: repeat(2, 1fr);
          .item {
            width: 418px;
            height: 208px;
            border: 1px solid #ffffff;
            padding: 40px;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
              width: 60px;
              height: 60px;
            }
            .label_text {
              font-size: 24px;
              font-weight: 600;
              color: #ffffff;
              line-height: 33px;
              padding: 20px 0 10px;
              text-align: left;
            }
            .description {
              font-size: 18px;
              font-weight: 100;
              color: #ffffff9e;
              line-height: 28px;
              padding-top: 8px;
              text-align: left;
            }
          }
        }
      }
      .function_box::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('https://sys.huwei.xin:7443/huwei-website/module_bg.png') no-repeat 100% 100%;
        background-size: contain;
        opacity: .7;
      }
    }
    .hardware_box {
      background: #ffffff;
      height: 956px;
      padding: 130px 200px;
      box-sizing: border-box;
      .label {
        font-size: 24px;
        font-weight: 800;
        color: #0F0F0F;
        line-height: 30px;
        border-left: 4px solid #1667FF;
        padding-left: 8px;
      }
      .en_label {
        font-size: 18px;
        font-weight: 600;
        color: #5E6070;
        line-height: 22px;
        padding: 8px 12px;
      }
      .hardware_content {
        display: flex;
        padding: 80px 68px 80px 28px;
        width: 100%;
        box-sizing: border-box;
        .right_img {
          img {
            width: 801px;
            height: 484px;
          }
        }
        .content_box {
          flex: 1;
          display: flex;
          flex-direction: column;
          .title_box {
            display: grid;
            grid-template-columns: 112px 112px;
            padding: 0 0 40px 40px;
            grid-gap: 200px;
            div {
              font-size: 24px;
              color: #000000;
              line-height: 33px;
            }
          }
          .item_box {
            display: grid;
            grid-template-columns: 50% 50%;
            padding-bottom: 16px;
            .item {
              display: grid;
              grid-template-columns: 26px auto;
              grid-gap: 16px;
              justify-content: start;
              align-items: center;
              width: 229px;
              height: 66px;
              background: #FAFAFA;
              box-sizing: border-box;
              padding-left: 40px;
              .index {
                font-size: 22px;
                color: #000000;
                font-weight: 600;
                line-height: 30px;
              }
              .item_text {
                font-size: 18px;
                font-weight: 100;
                color: #000000;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
    .support_nav {
      height: 716px;
      background: url('https://sys.huwei.xin:7443/huwei-website/decision_support_bg.png');
      .text_bg {
        position: absolute;
        width: 883px;
        font-size: 200px;
        font-weight: bold;
        color: #F2F2F2;
        line-height: 180px;
        padding: 36px 20px;
      }
      .function_box {
        padding: 153px 208px 80px;
        position: relative;
        float: right;
        .label {
          font-size: 24px;
          font-weight: 800;
          color: #0F0F0F;
          line-height: 30px;
          text-align: right;
          border-right: 4px solid #1667FF;
          padding-right: 8px;
        }
        .en_label {
          font-size: 18px;
          font-weight: 600;
          color: #5E6070;
          line-height: 22px;
          padding: 8px 12px;
        }
      }
      .desc_box {
        padding: 217px 0 0 205px;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: #000000;
          line-height: 33px;
        }
        .desc {
          font-size: 18px;
          font-weight: 400;
          color: #5E6070;
          line-height: 22px;
          padding-top: 10px;
          white-space: nowrap;
        }
      }
      .step_box {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: auto 111px auto 111px auto;
        box-sizing: border-box;
        padding: 41px 200px;
        .item {
          width: 433px;
          height: 313px;
          background: #FFFFFF;
          border-radius: 4px;
          .index_box {
            width: 115px;
            height: 120px;
            position: relative;
            margin: 9px 32px;
            display: flex;
            align-items: center;
            .index_bg {
              position: absolute;
              font-size: 100px;
              color: #F6F6F6;
              line-height: 120px;
            }
            .label {
              position: relative;
              font-size: 28px;
              font-weight: 500;
              color: #02031F;
              line-height: 23px;
              padding-top: 70px;
            }
          }
          .decription {
            font-size: 18px;
            font-weight: 400;
            color: #2A2A2A;
            line-height: 25px;
            padding: 30px 32px;
          }
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #1667FF;
        }
      }
    }
    .core_box {
      background: #ffffff;
      height: 567px;
      padding: 130px 200px 130px;
      box-sizing: border-box;
      .label {
        font-size: 24px;
        font-weight: 800;
        color: #0F0F0F;
        line-height: 30px;
        border-left: 4px solid #1667FF;
        padding-left: 8px;
      }
      .en_label {
        font-size: 18px;
        font-weight: 600;
        color: #5E6070;
        line-height: 22px;
        padding: 8px 12px;
      }
      .menu_box {
        width: 100%;
        display: grid;
        justify-content: center;
        padding: 40px 238px;
        grid-gap: 20px;
        box-sizing: border-box;
        grid-template-columns: repeat(4, 1fr);
        .item {
          width: 346px;
          height: 247px;
          background: #FFFFFF;
          border-radius: 0px 100px 0px 0px;
          border: 1px solid #1667FF;
          padding: 30px 40px;
          box-sizing: border-box;
          // text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;
          img {
            width: 64px;
            height: 64px;
          }
          .label_text {
            font-size: 18px;
            font-weight: 600;
            color: #02031F;
            line-height: 25px;
            padding: 20px 0 10px;
          }
          .description {
            font-size: 14px;
            font-weight: 400;
            color: #656973;
            line-height: 18px;
            padding-top: 8px;
            text-align: left;
          }
        }
      }
    }
    .applications_nav {
      height: 790px;
      .function_box {
        padding: 10px 208px 52px;
        position: relative;
        float: right;
        .label {
          font-size: 24px;
          font-weight: 800;
          color: #0F0F0F;
          line-height: 30px;
          text-align: right;
          border-right: 4px solid #1667FF;
          padding-right: 8px;
        }
        .en_label {
          font-size: 18px;
          font-weight: 600;
          color: #5E6070;
          line-height: 22px;
          padding: 8px 12px;
        }
      }
      .hotel_box {
        width: 100%;
        display: grid;
        justify-content: center;
        padding: 0 200px 52px;
        grid-gap: 82px;
        box-sizing: border-box;
        grid-template-columns: repeat(3, 1fr);
        .content_box {
          width: 452px;
          height: 589px;
          position: relative;
          .img_bg {
            position: absolute;
            width: 452px;
            height: 589px;
          }
          .text_box {
            position: relative;
            padding: 175px 76px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .name {
              position: relative;
              font-size: 28px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 23px;
              text-align: center;
              padding-bottom: 40px;
            }
            .li {
              position: relative;
              font-size: 17px;
              font-weight: 100;
              color: #FFFFFF;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
